*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body, #root {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

.MqZHCq_header {
  color: #333;
  margin-top: 20px;
  margin-bottom: 1px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 600;
}

.MqZHCq_headerSuccess {
  color: #000;
  box-shadow: var(--box-shadow-distance) var(--box-shadow-distance) 0 0 #404040;
  background-color: #ff0;
  border: 3px solid #000;
  width: 300px;
  margin: 50px auto 0;
}

.MqZHCq_invisiblePlaceholder {
  visibility: hidden;
  display: inline;
}

.MqZHCq_nothingFake1234 {
  color: #3884ff;
}

/*# sourceMappingURL=index.4c446fbc.css.map */
